import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CreditCard from '@material-ui/icons/CreditCard';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Input from '@material-ui/core/Input';
import CalendarToday from '@material-ui/icons/CalendarToday';
import VpnKey from '@material-ui/icons/VpnKey';
import AddShopp from '@material-ui/icons/AddShoppingCart';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';
import { AlertPop } from '../utils';

//Referência/Citação
//https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/checkout

//const baseUrl = 'http://localhost:5001/concreteja-cb4d6/us-central1';
const baseUrl = 'https://us-central1-concreteja-cb4d6.cloudfunctions.net';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
    
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },  
}));

const regexNumero = /([^\d])+/gim;
//Pegar ano do nodejs server
const anoAtual = +new Date().getFullYear().toString().substr(2,2);
const anoAtualFull = new Date().getFullYear();

export default function Checkout() {

  const classes = useStyles();
  const { pedidoId } = useParams();
  
  //REF'S
  const formPay = useRef(null);  
  
  //STATES
  const [bandeiraCartao, setBandeiraCartao] = useState({id:'', thumbnail:'', number:''});  
  const [validadeCartao, setValidadeCartao] = useState('');
  const [codigoCartao, setCodigoCartao] = useState('');
  const [styleClassNumeroCartao, setStyleClassNumeroCartao] = useState({backgroundImage: '', backgroundRepeat:'', backgroundPosition: ''});
  const [validacaoNumeroCartao, setValidacaoNumeroCartao] = useState({valido: true, mensagem: ''});
  const [validacaoValidadeCartao, setValidacaoValidadeCartao] = useState({valido: true, mensagem: ''});
  const [parcela, setParcela] = useState(-1);
  const [parcelas, setParcelas] = useState([]);
  const [submiting, setSubmiting] = useState(true);
  const [token, setToken] = useState('');
  const [resumoPagamento, setResumoPagamento] = useState({pedidoId:'', valorFormat: ''});
  const [textButton, setTextButton] = useState('Pagar');  

  useEffect(()=>{    
    obterResumoPagamento();    
  },[]);

  const obterResumoPagamento = async () => {    
    setSubmiting(true);
    setTextButton('Buscando dados...');    
    let resp = await fetch(`${baseUrl}/checkPayment`, {
      method: 'POST', 
      body: pedidoId
    });
    if(resp.ok){
      let pedido = await resp.json();
      if(pedido.ok){
        setResumoPagamento(pedido.data);
        setSubmiting(false);
        setTextButton('Pagar');
      }
      else {
        AlertPop.error(pedido.data.mensagem);
        setTimeout(()=>{
          document.location.href = '/';
        },6000);
      }
    }
    else{
      AlertPop.error('Houve um problema ao obter o pedido.');      
      setTimeout(()=>{
        document.location.href = '/';
      },6000);
    }
  }

  const obterBandeiraCartaoMP = seisPrimeirosNumeros => {
    return new Promise((resolve, reject)=>{
      try{
        let setPaymentMethodInfo = (status, response) =>{          
          if(status === 200){
            let respBand = response[0];
            resolve(respBand);
          }
          else if(status >= 400)
          {
            reject(response);
          }
        }      
        window.Mercadopago.getPaymentMethod({
          'bin': seisPrimeirosNumeros
        }, setPaymentMethodInfo);
      }
      catch (e){
        reject(e);
      }
    });
  }

  const obterParcelasMP = (seisPrimeirosNumeros, valor) => {    
    return new Promise((resolve, reject)=>{
      try{        
        let setInstallmentInfo = (status, response) =>{          
          if(status === 200){
            let respParc = response[0];
            resolve(respParc);
          }
          else if(status >= 400)
          {
            reject(response);
          }
        }      
        window.Mercadopago.getInstallments({
          'bin': seisPrimeirosNumeros,
          'amount': valor
        }, setInstallmentInfo);
      }
      catch (e){
        reject(e);
      }
    });
  }

  const setBandeira = (respBand, value) => {    
    setBandeiraCartao({id: respBand.id, thumbnail: respBand.secure_thumbnail, number: value});
    setStyleClassNumeroCartao({backgroundImage: `url(${respBand.secure_thumbnail})`, backgroundRepeat:'no-repeat', backgroundPosition: 'right'});    
  }

  const keyUpNumeroCartao = e => {

    let value = e.target.value.replace(regexNumero,'').replace(' ','').replace(' ','').replace(' ','');
    let fatias = Math.floor(value.length/4);
    let returnValue = '';   

    if(value.length >= 6 && (bandeiraCartao.id === '' || value.substr(0,6) !== bandeiraCartao.number)){      
      obterBandeiraCartaoMP(value)
      .then(respBand=>{
        setBandeira(respBand, value);
      })
      .catch(error=>{
        setValidacaoNumeroCartao({valido: false, mensagem: 'Cartão inválido ou não permitido!'});
        console.error('obterBandeiraCartaoMP:error =>', error);
      });

      obterParcelasMP(value, resumoPagamento.valor)
      .then(respParc=>{
        setParcelas(respParc.payer_costs); 
        setParcela(1);
      })
      .catch(error=>{
        console.error('obterParcelasMP:error =>', error);
      });
    }
    else if(value.length < 6){
      setBandeira({id:'', secure_thumbnail: ''}, '');
      setValidacaoNumeroCartao({valido: true, mensagem: ''});
      setParcelas([]);
      setParcela(-1);
    }
    
    if(fatias >= 1){      
      returnValue += value.length > 4 ? value.substr(0,4)+' ' : value;
      if(fatias >= 2){        
        returnValue += value.length > 8 ? value.substr(4,4)+' ' : value.substr(4,4);
        if(fatias >= 3){
          returnValue += value.length > 12 ? value.substr(8,4)+' ' : value.substr(8,4);          
          returnValue += value.substr(12,4);          
        }
        else
        {
          returnValue += value.substr(8,4)
        }
      }
      else
      {
        returnValue += value.substr(4,4)
      }
    }
    else
    {
      returnValue += value;
    }    

    e.target.value = returnValue;
  };

  const keyUpValidadeCartao = e =>{

    let value = e.target.value.replace(regexNumero,'').replace('/','');
    let returnValue = value; 
    let msgAnoInvalido = 'Ano inválido ou cartão já está vencido {ano}!';
    
    if(value.length > 2){
      returnValue = value.substr(0,2)+'/'+value.substr(2);    
    }

    if(value.length >= 2 && +value.substr(0,2) > 12){
      setValidacaoValidadeCartao({valido: false, mensagem: 'Mês inválido!'});
    }
    else if(value.length === 4 && +value.substr(2,2) < anoAtual){
      setValidacaoValidadeCartao({valido: false, mensagem: msgAnoInvalido.replace('{ano}', value.substr(2,2))});
    }
    else if(value.length === 6 && +value.substr(2,4) < anoAtualFull){
      setValidacaoValidadeCartao({valido: false, mensagem: msgAnoInvalido.replace('{ano}', value.substr(2,4))});
    }    
    else{        
      setValidacaoValidadeCartao({valido: true, mensagem: ''});
    }      

    setValidadeCartao(returnValue);
    e.target.value = returnValue;    
  }

  const keyUpCodigoCartao = e =>{
    e.target.value = e.target.value.replace(regexNumero,'');
    setCodigoCartao(e.target.value);
  }

  const keyPressDownNumero = e => {    
    e.nativeEvent.returnValue = e.key === 'Tab' || e.key === 'Backspace' || !isNaN(+e.key);
  }

  const onDragDropPaste = e => {
    e.nativeEvent.returnValue = false;
  }  

  const changeParcela = (event) => {
    setParcela(event.target.value);
  };

  const submit = async e => {

    e.preventDefault();

    setSubmiting(true);       
    setTextButton('Efetuando pagamento, aguarde...');
    
    window.Mercadopago.createToken(formPay.current, async (status, resp) => {

      if(status === 200 || status === 201){

        setToken(resp.id);

        let chavaValor = JSON.parse(atob(pedidoId));
        
        let respPay = await fetch(`${baseUrl}/runPayment`, {
          method: 'POST', 
          body: JSON.stringify({
            valorCompra: resumoPagamento.valor,
            token: resp.id,
            descricao: resumoPagamento.descricao,
            parcela: parcela,
            bandeira: bandeiraCartao.id,
            email: resumoPagamento.email,
            uKey: chavaValor.uKey,
            pKey: chavaValor.pKey
          })
        });
  
        if(respPay.ok){

          let result = await respPay.json();
  
          if(result.data.aprovado){
            AlertPop.success('Pagamento confirmado com sucesso.');
          }
          else {
            AlertPop.error('#03 Não foi possível efetuar o pagamento, favor verificar as informações prestadas.');
          }          
        }
        else
        {
          AlertPop.error('#02 Não foi possível efetuar o pagamento, favor verificar as informações prestadas.');
        }
      }
      else {
        AlertPop.error('#01 Não foi possível efetuar o pagamento, favor verificar as informações prestadas.');
      }

      setSubmiting(false);
      setTextButton('Pagar');
      
    });
  }

  return (
    <React.Fragment>      
      <CssBaseline />
      <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: 'white',
          opacity: 0.6,
          width: '100%',
          height: '100%',
          zIndex: 100
        }} hidden={!submiting}>
      </div>      
      <div style={{
          position: 'fixed',          
          top: '50%',
          left: '50%',          
          textAlign: 'center',
          verticalAlign: 'center',
          zIndex: 101,
          transitionDelay: '1s',
          transform: 'translate(-50%, -50%)'
        }} hidden={!submiting}>
        <CircularProgress />
      </div>
      <main className={classes.layout}>
        <form action="/" method="post" id="pay" name="pay" ref={formPay}>
          <input type="hidden" name="amount" id="amount" value={resumoPagamento.valor || ''} />
          <input type="hidden" name="transaction_amount" id="transaction_amount" value={resumoPagamento.valor || ''}  />
          <input type="hidden" name="description" id="description" value={resumoPagamento.descricao || ''} />
          <input type="hidden" id="email" name="email" value={resumoPagamento.email || ''} />
          <input type="hidden" name="payment_method_id" id="payment_method_id" value={bandeiraCartao.id || ''}/>
          <input type="hidden" id="docType" data-checkout="docType" value={resumoPagamento.docType || ''}/>
          <input type="hidden" id="docNumber" data-checkout="docNumber" value={resumoPagamento.cpf_cnpj || ''} />
          <input type="hidden" id="cardExpirationMonth" data-checkout="cardExpirationMonth" value={validadeCartao.substr(0,2)} />
          <input type="hidden" id="cardExpirationYear" data-checkout="cardExpirationYear" value={validadeCartao.substr(3)} />
          <input type="hidden" id="securityCode" data-checkout="securityCode" value={codigoCartao} />
          <input type="hidden" id="installments" name="installments" value={parcela} />
          <input type="hidden" id="token" name="token" value={token} />

          <Paper className={classes.paper}>        
            <Typography component="h1" variant="h5" align="center">            
              Pagamento
            </Typography>
            <center><img style={{height: 'auto', width: '30%', display: 'none'}} src={"img/logo.png"} alt="logo" /></center>
            <hr/>
            <React.Fragment>            
              <React.Fragment>
                <React.Fragment>                
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <center><img alt="..." style={{maxWidth: '70%', height: 'auto'}} src={resumoPagamento.urlLogoEmpresa} hidden={!resumoPagamento.pedidoId} /></center>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel htmlFor="input-with-icon-adornment">Pedido</InputLabel>
                          <Input
                            id="pedidoId"
                            startAdornment={
                              <InputAdornment position="start">
                                <AddShopp />
                              </InputAdornment>
                            }
                            value={resumoPagamento.pedidoId || ''}
                            disabled
                          />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>                    
                      <TextField
                        fullWidth
                        label="Valor"
                        id="transaction_amount"
                        name="transaction_amount"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,                            
                          }}
                        value={(resumoPagamento.valorFormat || '').replace('R$ ','')}
                        disabled
                      />                    
                    </Grid>                   
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth className={classes.margin}>
                        <InputLabel htmlFor="input-with-icon-adornment">Nome no cartão</InputLabel>
                        <Input                          
                          id="cardholderName"
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          }
                          placeholder="Dom Pedro"
                          inputProps={{
                            maxLength: 250,
                            'data-checkout':'cardholderName'
                          }}
                          tabIndex={0}                        
                        />                      
                      </FormControl>                    
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth className={classes.margin} error={!validacaoNumeroCartao.valido}>
                        <InputLabel htmlFor="input-with-icon-adornment">Número do cartão</InputLabel>
                        <Input                          
                          id="cardNumber"
                          startAdornment={
                            <InputAdornment position="start">
                              <CreditCard />
                            </InputAdornment>
                          }                  
                          type="tel"      
                          placeholder="0000 1234 0000 4321"
                          onKeyDown={keyPressDownNumero}
                          onKeyPress={keyPressDownNumero}
                          onKeyUp={keyUpNumeroCartao}
                          onPaste={onDragDropPaste}
                          onDrag={onDragDropPaste}
                          onDrop={onDragDropPaste}
                          inputProps={{
                            maxLength: 19,
                            style: styleClassNumeroCartao,
                            'data-checkout': 'cardNumber'
                          }}
                          tabIndex={1}
                          error={!validacaoNumeroCartao.valido}
                        />
                        <span style={{color: 'red'}} hidden={validacaoNumeroCartao.valido}>{validacaoNumeroCartao.mensagem}</span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>                  
                      <FormControl fullWidth className={classes.margin} error={!validacaoValidadeCartao.valido}>
                          <InputLabel htmlFor="input-with-icon-adornment">Validade do cartão</InputLabel>
                          <Input
                            id="validadeCartao"
                            startAdornment={
                              <InputAdornment position="start">
                                <CalendarToday />
                              </InputAdornment>
                            }
                            type="tel"
                            placeholder="12/2029 ou 12/29"
                            onKeyDown={keyPressDownNumero}
                            onKeyPress={keyPressDownNumero}
                            onKeyUp={keyUpValidadeCartao}
                            onPaste={onDragDropPaste}
                            onDrag={onDragDropPaste}
                            onDrop={onDragDropPaste}                          
                            inputProps={{
                              maxLength: 7
                            }}
                            tabIndex={2}
                            error={!validacaoValidadeCartao.valido}
                          />
                          <span style={{color: 'red'}} hidden={validacaoValidadeCartao.valido}>{validacaoValidadeCartao.mensagem}</span>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel htmlFor="input-with-icon-adornment">Código Segurança CVC</InputLabel>
                          <Input                            
                            id="securityCode"
                            startAdornment={
                              <InputAdornment position="start">
                                <VpnKey />
                              </InputAdornment>
                            }
                            type="tel"
                            placeholder="001"
                            onKeyDown={keyPressDownNumero}
                            onKeyPress={keyPressDownNumero}
                            onKeyUp={keyUpCodigoCartao}
                            onPaste={onDragDropPaste}
                            onDrag={onDragDropPaste}
                            onDrop={onDragDropPaste}                           
                            inputProps={{
                              maxLength: 4,
                              'data-checkout': 'securityCode'
                            }}
                            tabIndex={3}
                          />
                        </FormControl>   
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="label-installments">Parcelas</InputLabel>
                        <Select
                          labelId="label-installments"
                          id="installments"
                          fullWidth
                          name="installments"        
                          value={parcela}             
                          onChange={changeParcela}
                          startAdornment={
                            <InputAdornment position="start">
                              <MonetizationOn />
                            </InputAdornment>
                          }>
                          {
                            parcelas.length > 0 ? 
                            parcelas.map((item, index)=><MenuItem key={index} value={item.installments}>{item.recommended_message}</MenuItem>) :
                            <MenuItem value="-1">
                              <em>Selecione</em>
                            </MenuItem>
                          }                          
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </React.Fragment>
                <div className={classes.buttons}>                
                  <div style={{paddingTop: '25px'}}>              
                  </div>
                  <div>
                    <Button
                      type="submit"
                      variant="contained" 
                      onClick={submit} 
                      color="primary" 
                      className={classes.button}
                      startIcon={submiting ? <CircularProgress  size={24} /> : <CreditCard />}
                      disabled={submiting}
                      tabIndex={4}>                        
                      {textButton}
                    </Button>
                  </div>
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className="item"><center><img style={{height: 'auto', width: '50%'}} src="img/securessl.png" alt="ssl" /></center></div>
                  <div className="item">
                    <center>
                      <a href="https://transparencyreport.google.com/safe-browsing/search?url=concreteja.com.br" target="_blank" rel="noopener noreferrer">
                        <img style={{height: 'auto', width: '55%'}} src="img/google-safe-browsing.png" alt="google safe" />
                      </a>
                    </center>
                  </div>
                  <div className="item"><center><img src="img/firebase.png" alt="ssl" style={{height: 'auto', width: '70%'}}></img></center></div>
                  <div className="item"><center><img style={{height: 'auto', width: '70%'}} src="img/mercado-pago-logo-6.png" alt="MercadoPago" /></center></div>
                </div>     
                <hr/>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className="item-cartao">
                    <img style={{height: 'auto', width: '70%'}} src="img/master.gif" alt="master"/>
                  </div>
                  <div className="item-cartao">
                    <img style={{height: 'auto', width: '70%'}} src="img/visa.gif" alt="visa"/>
                  </div>
                  <div className="item-cartao">
                    <img style={{height: 'auto', width: '70%'}} src="img/amex.gif" alt="amex"/>
                  </div>
                  <div className="item-cartao">
                    <img style={{height: 'auto', width: '70%'}} src="img/elo.gif" alt="elo"/>
                  </div>
                  <div className="item-cartao">
                    <img style={{height: 'auto', width: '70%'}} src="img/hipercard.gif" alt="hipercard"/>
                  </div>
                </div>
              </React.Fragment>
            </React.Fragment>
          </Paper>
        </form>        
      </main>
    </React.Fragment>
  );
}