import React, {useEffect} from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import Checkout from './component/Checkout';
import { PopMsg, AlertPop } from './utils/PopMsg';
import Container from '@material-ui/core/Container';

function App() {
  return (
    <Container>
      <Switch>
          <Route exact path="/:pedidoId">
            <Checkout />
          </Route>
          <Route path="*">
            <Run404 />
          </Route>
      </Switch>
      <PopMsg />
    </Container>
  );
}

function Run404(){

  useEffect(()=>{

    const iteration = () =>{
      setTimeout(()=>{
        AlertPop.info('Bem vindo ao Concrete Já.', 5000);
      },1000);
  
      setTimeout(()=>{
        AlertPop.warning('Já pensou em vender mais e de forma on-line?', 5000);
      },7000);
  
      setTimeout(()=>{
        AlertPop.success('Sua responsta foi sim, então clique no link acima e conheça-nos.', 5000);
      },15000);
  
      setTimeout(()=>{
        AlertPop.error('Não tem interesse, não tem problema, divulgue então!!', 5000);
      },22000);
  
      setTimeout(()=>{
        AlertPop.info('Concrete Já. A solução definitiva para sua concreteira acesse www.concreteja.com.br.', 360000);
      },29000);  
    };   

    iteration();

    setInterval(iteration, 360000+29000+1);

  });

  return(
    <div className="container404">
      <div style={{display: 'block', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center'}} className="item404">
        <img src="img/logo.png" className="App-logo" alt="logo" />
        <br/>
        <a
          className="App-link"
          href="https://concreteja.com.br"
          target="_blank"
          rel="noopener noreferrer">
          www.concreteja.com.br
        </a>        
      </div>      
  </div>     
  );
}

export default App;
